@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--counters {
  $wide-width: 60.5rem;
  $narrow-width: 46.625rem;
  $divider-wide: 5.625rem;
  $divider-wide-m: 30px;
  $divider-narrow: 4.375rem;
  $divider-narrow-m: 30px;

  padding: $grid-gutter-width / 2;

  @include media-breakpoint-down(lg) {
    padding: 0 $grid-gutter-width / 2;
  }

  &.counters--wrapp-to-container {
    &:not(:first-child) {
      margin-top: 6.25rem;

      @include media-breakpoint-down(xl) {
        margin-top: 4rem;
      }

      @include media-breakpoint-down(lg) {
        margin-top: 2rem;
      }
    }

    .counters__wrapper {
      max-width: 82.9375rem;
      margin: 0 auto;
      padding: 64px 0;

      @include media-breakpoint-down(xl) {
        max-width: 60rem;
      }
    }

    .section--carousel {
      > .container {
        padding: 0;
      }

      .swiper {
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }

    .carousel-list__wrapper {
      max-width: 78rem;
      margin: 0 auto;
      padding: 0 1rem;
    }
  }

  .section--carousel:only-child {
    padding-top: 0;
  }

  .star-icon {
    display: inline-block;
    margin-bottom: 2.5rem;
    transform-origin: center;
    animation: star-blinking 4s infinite ease-in-out;

    @include media-breakpoint-down(lg) {
      margin-bottom: 36px;
    }

    svg {
      width: 5.625rem;
      height: 5.625rem;

      @include media-breakpoint-down(lg) {
        width: 62px;
        height: 62px;
      }
    }
  }

  .counters {
    &__wrapper {
      background-color: $black;
      overflow: hidden;

      @include media-breakpoint-down(lg) {
        padding: 3.25rem 0;
      }
    }

    &__line-img {
      display: inline-block;
      overflow: hidden;
      width: 0;
      margin-top: $divider-wide;

      @include media-breakpoint-down(lg) {
        margin-top: $divider-wide-m;
      }

      //&.is-animated {
      //  animation: width 5s ease-in-out;
      //  width: 100%;
      //}
    }

    &__list {
      margin-top: $divider-wide;

      @include media-breakpoint-down(lg) {
        margin-top: $divider-wide-m;
      }
    }

    &__content {
      &--wide {
        max-width: $wide-width;
        margin: 0 auto;

        .counters__line-img {
          margin-top: $divider-wide;

          @include media-breakpoint-down(lg) {
            margin-top: $divider-wide-m;
          }

          svg {
            width: 43.125rem;
            height: auto;
          }
        }

        .counters__list {
          margin: $divider-wide auto 0;

          @include media-breakpoint-down(lg) {
            margin: $divider-wide-m auto 0;
          }
        }
      }

      &--narrow {
        max-width: $narrow-width;
        margin: 0 auto;

        .counters__line-img {
          margin-top: $divider-narrow;

          @include media-breakpoint-down(lg) {
            margin-top: $divider-narrow-m;
          }

          svg {
            width: 34.5625rem;
            height: auto;
          }
        }

        .counters__list {
          margin: $divider-narrow auto 0;

          @include media-breakpoint-down(lg) {
            margin: $divider-narrow-m auto 0;
          }
        }
      }

      .counters__list {
        list-style: none;
        display: block;
        max-width: 43.75rem;
        padding: 0;
        text-align: center;

        li {
          padding: 1.75rem 0;
          margin: 0;
          border-bottom: 1px solid $gray-800;

          &:nth-child(even) {
            * {
              color: $primary;
            }
          }

          &:first-of-type {
            padding-top: 0;
          }

          h3 {
            display: inline-block;
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
              max-width: 270px;
            }
          }
        }
      }

      .counters__line-img {
        svg {
          @include media-breakpoint-down(md) {
            max-width: 320px;
            height: auto;
          }

          @media (max-width: 374px) {
            max-width: 260px;
            height: auto;
          }
        }
      }
    }
  }

  .counter-slider {
    &__wrapper {
      margin-right: -0.75rem;
      margin-left: -0.75rem;

      &--narrow {
        margin-top: $divider-narrow;

        @include media-breakpoint-down(lg) {
          margin-top: $divider-narrow-m;
        }
      }

      &--wide {
        margin-top: $divider-wide;

        @include media-breakpoint-down(lg) {
          margin-top: $divider-wide-m;
        }
      }
    }

    .splide__track {
      overflow: visible;
    }

    &.splide:not(.is-active) {
      .splide__list {
        display: flex;
        justify-content: center;
      }

      .splide__slide {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }

      .services__item {
        width: 100%;
      }
    }

    .splide__slide {
      display: flex;
    }
  }

  .counter-item {
    padding: 1.25rem 2.5rem;
    min-width: 16.875rem;

    @include media-breakpoint-down(md) {
      padding: 18px 38px;
      min-width: 220px;
    }

    &--outline {
      border: 1px solid $border-color;
    }

    &--fill {
      background-color: $purple;
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 4px;
      }
    }

    .number {
      position: relative;

      &__placeholder {
        opacity: 0;
        visibility: hidden;
      }

      &__counter {
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
      }
    }

    &__text {
      margin-bottom: 0;
      font-weight: 500;
      font-size: $font-size-sm;
      line-height: 1.2857;

      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .counters-t {
    &__wrapper {
      padding-top: 8.75rem;

      @include media-breakpoint-down(sm) {
        padding-top: 1.875rem;
      }
    }

    &__title {
      margin-bottom: 2.1875rem;
      font-size: $font-size-base;
      line-height: (24 / 16);

      @include media-breakpoint-down(sm) {
        margin-bottom: 1.5625rem;
      }
    }

    &-box {
      background-color: $white;
      font-weight: 500;
      padding: 1.5rem 2.6875rem 1.5rem 1.5rem;
      width: 43.375rem;
      margin: 0 $grid-gutter-width / 4 $grid-gutter-width;
      max-width: calc(50% - #{$grid-gutter-width / 2});

      @include media-breakpoint-down(xl) {
        max-width: calc(100% - #{$grid-gutter-width});
      }

      @include media-breakpoint-down(sm) {
        padding: 1.25rem;
      }

      &--1 {
        width: 51.25rem;
        max-width: calc(100% - #{$grid-gutter-width});
      }

      &__image {
        z-index: 1;

        &-wrapper {
          flex: 0 1 44%;
          display: inline-block;
          padding-bottom: 55.6%;

          @include media-breakpoint-down(sm) {
            flex: 0 1 100%;
            margin-bottom: 1.5625rem;
            padding-bottom: 88%;
          }

          &:hover {
            .counters-t-box__play {
              opacity: 0.7;
            }
          }
        }
      }

      &__play {
        background-image: url('data:image/svg+xml,%3Csvg width=\'56\' height=\'56\' viewBox=\'0 0 56 56\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg filter=\'url(%23filter0_b_434_1426)\'%3E%3Crect width=\'56\' height=\'56\' rx=\'14.573\' fill=\'%237B7F84\'/%3E%3Crect x=\'0.404805\' y=\'0.404805\' width=\'55.1904\' height=\'55.1904\' rx=\'14.1682\' stroke=\'%23CAD0D6\' stroke-width=\'0.809611\'/%3E%3C/g%3E%3Cpath d=\'M38.903 26.7609C39.8458 27.318 39.8458 28.682 38.903 29.2391L21.1715 39.7168C20.2121 40.2838 19 39.5921 19 38.4777L19 17.5223C19 16.4079 20.2121 15.7162 21.1715 16.2832L38.903 26.7609Z\' fill=\'white\'/%3E%3Cdefs%3E%3Cfilter id=\'filter0_b_434_1426\' x=\'-3.23844\' y=\'-3.23844\' width=\'62.4769\' height=\'62.4769\' filterUnits=\'userSpaceOnUse\' color-interpolation-filters=\'sRGB\'%3E%3CfeFlood flood-opacity=\'0\' result=\'BackgroundImageFix\'/%3E%3CfeGaussianBlur in=\'BackgroundImageFix\' stdDeviation=\'1.61922\'/%3E%3CfeComposite in2=\'SourceAlpha\' operator=\'in\' result=\'effect1_backgroundBlur_434_1426\'/%3E%3CfeBlend mode=\'normal\' in=\'SourceGraphic\' in2=\'effect1_backgroundBlur_434_1426\' result=\'shape\'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 56px;
        height: 56px;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.3s ease;
        z-index: 2;

        @include media-breakpoint-down(sm) {
          left: 1rem;
          top: auto;
          bottom: 1rem;
          transform: none;
        }
      }

      &__content {
        flex: 0 1 54%;
        padding: 0 0 0 38px;

        @include media-breakpoint-down(sm) {
          flex: 0 1 100%;
          padding: 0;
        }
      }

      &__title {
        margin-bottom: 1.75rem;
      }

      &__text {
        margin: 0;

        &--blockqoute {
          position: relative;

          &::before {
            content: '';
            width: 1.625rem;
            height: 1.125rem;
            background-image: url('data:image/svg+xml,%3Csvg width=\'26\' height=\'18\' viewBox=\'0 0 26 18\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M8.21053 18H0L7.72755 0H12.9598L8.21053 18ZM21.2508 18H13.0402L20.7678 0H26L21.2508 18Z\' fill=\'%23D157A3\'/%3E%3C/svg%3E%0A');
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            margin-bottom: 1.375rem;
          }
        }
      }

      &__info {
        margin-top: 0.9375rem;
      }
    }
  }
}

@include color-mode(dark) {
  .section--counters {
    .counters__wrapper {
      background-color: #b6c7da !important;

      &.color--white {
        .content-wrapper {
          > h2, > .h2, > h3, > .h3, > p, > p > * {
            color: $black !important;
          }
        }
      }
    }

    .counters__line-img {
      svg {
        stop {
          stop-opacity: 1;
          stop-color: $secondary;
        }
      }
    }

    .counters__list {
      h3 {
        color: $black;
      }
    }

    .counter-item {
      &--fill {
        .counter-item__title,
        .counter-item__text {
          color: $white !important;
        }
      }

      &--outline {
        border: 1px solid $black;
      }

      &__text {
        color: $black;
      }

      &__title {
        color: $black !important;
      }
    }
  }

  .counters-t {
    &-box {
      background-color: #516072;

      &__title,
      &__text,
      &__name,
      &__position {
        color: $white !important;
      }
    }

    &__title {
      color: $black;
    }
  }
}

.counters__wrapper {
  &:not([style*="background-color: #000000"]) {
      @include media-breakpoint-down(lg) {
        border-radius: 0 !important;
        margin: 0 -0.625rem;
      }
  }

  &[style='background-color: #c6d9ee'] {
    .counter-item {
      color: $white;
    }

    @include theme-mode(dark) {
      color: $black;
    }

    .counters-accordion {
      @include theme-mode(light) {
        color: $black;

        .accordion-header h3::after {
          background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'25\' height=\'24\' viewBox=\'0 0 25 24\' fill=\'none\'%3E%3Cpath d=\'M18.5 15L12.5 9L6.5 15\' stroke=\'black\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3E%3C/svg%3E');
        }
      }

      @include theme-mode(dark) {
        color: $black;

        .accordion-header h3::after {
          background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'25\' height=\'24\' viewBox=\'0 0 25 24\' fill=\'none\'%3E%3Cpath d=\'M18.5 15L12.5 9L6.5 15\' stroke=\'black\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3E%3C/svg%3E');
        }
      }
    }
  }
}

.counters-accordion {
  max-width: 53.875rem;
  margin: 0 auto;

  @include theme-mode(light) {
    color: $white;
  }

  @include theme-mode(dark) {
    color: $black;
  }

  .accordion-header {
    border-bottom: 1px solid $gray-900;
    padding: 1.25rem 0;
    margin-bottom: 1.25rem;

    @include media-breakpoint-down(md) {
      padding: 0.5rem 0;
      margin-bottom: 0.5rem;
    }

    h3 {
      position: relative;
      padding-right: 1.875rem;
      transition: color 0.3s ease;
      color: inherit;

      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0.125rem;
        width: 1.5rem;
        height: 1.5rem;
        transition: transform 0.3s ease;
        transform: rotate(180deg);
        background-repeat: no-repeat;

        @include theme-mode(light) {
          background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'25\' height=\'24\' viewBox=\'0 0 25 24\' fill=\'none\'%3E%3Cpath d=\'M18.5 15L12.5 9L6.5 15\' stroke=\'white\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3E%3C/svg%3E');
        }

        @include theme-mode(dark) {
          background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'25\' height=\'24\' viewBox=\'0 0 25 24\' fill=\'none\'%3E%3Cpath d=\'M18.5 15L12.5 9L6.5 15\' stroke=\'black\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3E%3C/svg%3E');
        }
      }
    }

    [aria-expanded='true'] {
      color: $primary;

      &::after {
        transform: rotate(0deg);
      }
    }
  }
}

.counters-columns-content {
  @include theme-mode(light) {
    color: $white;
  }

  @include theme-mode(dark) {
    color: $black;
  }

  h2 {
    font-weight: 300;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.75rem;
      margin-bottom: 1.5rem;
    }
  }
}

.single-service {
  &.postid-2377, &.postid-2561 {
    .section--counters .counter-item .number {
      margin-right: 0.5rem;
    }

    h2, h3 {
      margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.counters-position {
  &--content {
    margin-bottom: 4rem;

    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }

    .counter-slider__wrapper--wide {
      margin-top: 4rem;

      @include media-breakpoint-down(lg) {
        margin-top: 2rem;
      }
    }
  }
}
